import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import FieldDetailRoutes from "../modules/fielddetail/connector/FieldDetailRoutes";
import LoginRoutes from "../modules/login/connector/ModuleRoutes";
import MyFieldsRoutes from "../modules/myfields/connector/ModuleRoutes";
import NotificationsRoutes from "../modules/notifications/connector/ModuleRoutes";
import ProfileRoutes from "../modules/profile/connector/ModuleRoutes";
import SatelliteRoutes from "../modules/satellite/connector/ModuleRoutes";
import UserProfileRoutes from "../modules/userprofile/connector/ModuleRoutes";
import UserRoutes from "../modules/users/connector/ModuleRoutes";
import WeatherRoutes from "../modules/weather/connector/ModuleRoutes";
import SurveyRoutes from "../modules/survey/connector/ModuleRoutes";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import moment from "moment";
import { useNavigate } from "react-router";
import CorporateDashboardRoutes from "../modules/corporatedashboard/connector/ModuleRoutes";

const allowedPaths = [
  "/login",
  "/agreement",
  "/users",
  "/users/add",
  "/weatherDetail",
  "/myFields/detail",
  "/myFields",
  "/myfields/add",
  "/myFields/mapview",
  "/myFields/navigation",
  "/profile",
  "/profile/changepassword",
  "/notifications",
  "/userprofile",
  "/satellite",
  "/satellite/:fieldId",
  "/survey/list",
  "/survey/add",
  "/survey/update/:id",
  "/survey/editor/:id",
  "/survey/display/:type/:id",
  "/survey/question/report",
  "/survey/answer/report",
  "/mytask/list",
  "/mytask/detail/list/:id",
  "/mytask/detail/:id",
  "/task/list",
  "/task/add",
  "/task/update/:id",
  "/task/detail/:id",
  "/task/assign/:id",
  "/corporatedashboard",
];

function LoginCheck(props: any) {
  const [windowLoaded, setWindowLoadded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const token = localStorage.getItem("token");
      const expDate = token == null ? null : jwtDecode(token).exp;

      const convertedTimestamp = convertDateToTimestamp(
        new Date().toLocaleTimeString()
      );

      if (
        expDate == null ||
        (convertDateToTimestamp != null &&
          expDate < (convertedTimestamp ?? 0) &&
          window.location.pathname !== "/login")
      ) {
        let weatherFav = localStorage.getItem("weatherFav");
        localStorage.clear();
        if (weatherFav !== null) localStorage.setItem("weatherFav", weatherFav);
        navigate("/login");
      } else if (
        !allowedPaths.some((allowedPath: string) =>
          window.location.pathname
            .toLowerCase()
            .includes(allowedPath.toLowerCase())
        )
      ) {
        navigate("/corporatedashboard");
      }
    } catch (ex) {
      let weatherFav = localStorage.getItem("weatherFav");
      localStorage.clear();
      if (weatherFav !== null) localStorage.setItem("weatherFav", weatherFav);
      navigate("/login");
    }

    setWindowLoadded(true);
  }, []);

  const convertDateToTimestamp = (dateString: string) => {
    const date = moment(dateString, "h:mm:ss A");
    if (date.isValid()) {
      const timestamp = date.unix();
      return timestamp;
    } else {
      console.error("Invalid date string format");
      return undefined;
    }
  };

  return (
    <>
      {windowLoaded && (
        <>
          <LoginRoutes />
          <UserRoutes />
          <WeatherRoutes />
          <FieldDetailRoutes />
          <MyFieldsRoutes />
          <ProfileRoutes />
          <NotificationsRoutes />
          <UserProfileRoutes />
          <SatelliteRoutes />
          <SurveyRoutes />
          <CorporateDashboardRoutes />
        </>
      )}
    </>
  );
}

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="*"
          element={<LoginCheck pathName={window.location.pathname} />}
        />
      </Routes>
    </BrowserRouter>
  );
}
